export default function init() {  
  let isScriptLoaded = false;
  const loadScript = async () => {
    if (isScriptLoaded) return;

    import('swiper/swiper.min.css');
    import('@styles/blocks/b-project-hero-carousel.scss');
    const swiperModule = await import('swiper');
    const { default: Swiper, Autoplay } = swiperModule;
    Swiper.use(Autoplay);
    const carousel = document.querySelector('.js-project-hero-carousel__swiper');
    const projectHeadingCopy = document.querySelectorAll('.b-project-banner__content');
    const swiper = new Swiper(carousel, {
      allowTouchMove: true,
      loop: true,
      speed: 1000,
      initialSlide: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerGroup: 1,
    });
    swiper.on('realIndexChange', (swiperIndex) => {
      showCopy(swiperIndex.realIndex);
    });

    function showCopy(slideIndex) {
      projectHeadingCopy.forEach((headingCopy) => {
        const activeHeading = parseInt(headingCopy.dataset.slideIndex, 10);
        headingCopy.classList.toggle('show', activeHeading === slideIndex);
      });
    }
    
    isScriptLoaded = true;
  };

  const elements = [...document.querySelectorAll('.js-project-hero-carousel__swiper')];
  elements.forEach((element) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(element);
  });
}
  
