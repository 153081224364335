export default function init() {
  let isScriptLoaded = false;
  const loadScript = async () => {
    if (isScriptLoaded) return;
    
    import('prismjs/themes/prism.css');
    import('prismjs/plugins/line-numbers/prism-line-numbers.css');
    Promise.all([
      import('prismjs/prism'),
      import('prismjs/plugins/line-numbers/prism-line-numbers'),
    ]).then(() => {
      import('prismjs/components/prism-json');
      import('prismjs/components/prism-scss');
    });

    isScriptLoaded = true;
  };

  const elements = [...document.querySelectorAll('.b-code-snippet')];
  elements.forEach((element) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(element);
  });
}
