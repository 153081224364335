class VideoControls {
  constructor(el) {
    this.el = el;
    this.videoEl = this.el.previousElementSibling;
    this.playButton = this.el.querySelector('.js-video-play');
    this.pauseButton = this.el.querySelector('.js-video-pause');

    // Show the pause button if autoplay is enabled
    if (this.videoEl.getAttribute('autoplay') !== null) {
      this.showPauseButton();
    }

    // Play the video if it's paused or pause if currently playing
    this.el.addEventListener('click', () => {
      if (this.videoEl.paused || this.videoEl.ended) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    });

    this.videoEl.addEventListener('ended', () => {
      this.showPlayButton();
    });
  }

  showPauseButton() {
    this.playButton.style.display = 'none';
    this.pauseButton.style.display = 'inline-block';
  }

  showPlayButton() {
    this.pauseButton.style.display = 'none';
    this.playButton.style.display = 'inline-block';
  }

  playVideo() {
    this.showPauseButton();
    this.videoEl.play();
  }

  pauseVideo() {
    this.showPlayButton();
    this.videoEl.pause();
  }
}

export default function init() {
  document.querySelectorAll('.js-video-control').forEach((el) => {
    new VideoControls(el);
  });
}
