const modalDelay = 5000; // ms delay before showing the modal
const shownExpires = 365; // days before showing same modal again

/**
 * @param {Object} Cookies
 * @param {HTMLElement} notification
 */
function markAsShown(Cookies, notification) {
  Cookies.set(`notification-${notification.dataset.notificationId}`, 1, { expires: shownExpires });
}

/**
 * @param {Object} Cookies
 * @param {HTMLElement} notification
 */
function wasShown(Cookies, notification) {
  return Cookies.get(`notification-${notification.dataset.notificationId}`);
}

/**
 * @param {Object} Cookies
 * @param {HTMLElement} notification                                                                    ..
 */
function showModal(Cookies, notification) {
  import('glightbox/dist/css/glightbox.css');
  import('glightbox')
    .then((module) => {
      const GLightbox = module.default;
      GLightbox({
        selector: '.js-modal-notification',
        hideControls: true,
        content: notification.innerHTML,
        onClose: () => {
          markAsShown(Cookies, notification);
        },
      }).open();
    })
    .catch(err => console.error(`Could not load module: ${err}`));
}

export default async function init() {
  const notification = document.querySelector('.js-modal-notification');
  let Cookies;
  if (notification) {
    await import('js-cookie').then((module) => {
      Cookies = module.default;
    });
  }
  if (notification && !wasShown(Cookies, notification)) {
    // Add a listener for the button
    const button = notification.querySelector('.js-modal-notification__button');
    if (button) {
      button.addEventListener('click', () => {
        markAsShown(Cookies, notification);
      });
    }

    if (!Cookiebot.hasResponse) {
      // Wait for the cookies response
      window.addEventListener('CookiebotOnAccept', () => {
        if (Cookiebot.consent.marketing) {
          setTimeout(showModal, modalDelay, Cookies, notification);
        }
      });
    } else if (typeof Cookiebot !== 'undefined' && Cookiebot.consent.marketing) {
      // Show the popup
      setTimeout(showModal, modalDelay, Cookies, notification);
    }
  }
}
