// This module is used to track conversions in Plausible for both the 
// contact form and the newsletter signup form on the whitepaper landing page

/* global plausible */
export default function init() {
  const forms = [...document.querySelectorAll('.fui-form')];

  forms.forEach(/** @param {HTMLFormElement} form */ (form) => {
    const eventName = form.dataset.plausibleEventName;
    if (!eventName) {
      return;
    }
    form.addEventListener('onAfterFormieSubmit', (e) => {
      e.preventDefault();

      if (e.detail.nextPageId) {
        return;
      }

      const formData = new FormData(form);
      const selectedServices = formData.getAll('fields[whatCanWeHelpWith][]');
      const props = {};
      [...form.elements]
        .filter(el => 
          !el.disabled && el.type === 'checkbox' && el.value.length && el.name
            .startsWith('fields[whatCanWeHelpWith]'))
        .forEach((c) => {
          if (selectedServices.includes(c.value)) {
            props[c.value] = 'Yes';
          }
        });

      plausible(eventName, { props });
    });
  });
}
