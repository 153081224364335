function addButtonDecoration(el) {
  // Wrap inner content with a span element
  const innerSpan = document.createElement('span');
  innerSpan.className = 'e-button__inner';

  // Move the existing children of the button into the new span
  while (el.firstChild) {
    innerSpan.appendChild(el.firstChild);
  }

  // Add the new span as the only child of the button
  el.appendChild(innerSpan);

  // Create the decoration element as a string
  const decorationHTML = `
    <span class="e-button__hash-wrap" style="display: block;">
      <svg class="e-button__hash" role="presentation">
        <use xlink:href="#sprite-hash-lines"></use>
      </svg>
    </span>
  `;

  // Append the decoration HTML after the inner span
  el.insertAdjacentHTML('beforeend', decorationHTML);
}

export default function init() {
  document.querySelectorAll('.e-button').forEach((el) => addButtonDecoration(el));
}
