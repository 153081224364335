// This module is used to track conversions in LinkedIn 
// for both the contact form and the newsletter signup 
// form on the whitepaper landing page
/* global lintrk */
export default function init() {
  const forms = [...document.querySelectorAll('.fui-form')];

  forms.forEach(/** @param {HTMLFormElement} form */ (form) => {
    const conversionId = form.dataset.linkedInConversionId;
    if (!conversionId) {
      return;
    }
    form.addEventListener('onAfterFormieSubmit', (e) => {
      e.preventDefault();

      if (e.detail.nextPageId) {
        return;
      }

      lintrk('track', { conversion_id: conversionId });
    });
  });
}
