/**
 * This module is used to track the gclid query parameter in the URL.
 * We store the gclid in sessionStorage so that it can be used later
 * when the user submits a form.
 */

/**
 * Get the gclid query parameter from the URL
 * @returns {string} The gclid query parameter value
 */
const getGclidParam = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('gclid');
};

/**
 * Store tracking information in localStorage
 * @param {string} currentUrl - Full URL including query parameters
 */
const storeTrackingInfo = (currentUrl) => {
  // If paid traffic is already tracked, don't override the original landing page
  if (localStorage.getItem('paidTraffic') === 'true') {
    return;
  }

  localStorage.setItem('landingPage', currentUrl);
  localStorage.setItem('paidTraffic', 'true');
};

const populateFormFields = () => {
  const forms = [...document.querySelectorAll('.fui-form')];
  forms.forEach((form) => {
    const landingPage = localStorage.getItem('landingPage');
    const paidTraffic = localStorage.getItem('paidTraffic');

    const landingPageField = form.querySelector('input[name="fields[landingUrl]"]');
    const paidTrafficField = form.querySelector('input[name="fields[paidTraffic]"]');

    if (landingPageField && landingPage) {
      landingPageField.value = landingPage;
    }

    if (paidTrafficField && paidTraffic) {
      paidTrafficField.value = paidTraffic;
    }
  });
};

export default function init() {
  window.addEventListener('CookiebotOnAccept', () => {
    if (Cookiebot.consent.statistics && Cookiebot.consent.marketing) {
      const gclid = getGclidParam();
      if (gclid) {
        storeTrackingInfo(window.location.href);
      }
      populateFormFields();
    }
  });
}
