export default function init() {
  let isScriptLoaded = false;

  const loadScript = async () => {
    if (isScriptLoaded) return;

    import('swiper/swiper-bundle.min.css');
    const swiperModule = await import('swiper');
    const { default: Swiper, Pagination, Autoplay, EffectFade, Navigation } = swiperModule;
    const textImageBlockSwiper = document.querySelector('.js-text-image-block-swiper');
    if (textImageBlockSwiper) {
      new Swiper('.js-text-image-block-swiper', {
        modules: [Pagination, Autoplay, EffectFade],
        pagination: {
          el: '.js-text-image-block-swiper .swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        spaceBetween: 50,
        slidesPerView: 1,
        autoplay: {
          delay: 6000,
        },
        loop: true,
      });
    }

    const projectSwiper = document.querySelector('.js-project-swiper');
    if (projectSwiper) {
      new Swiper('.js-project-swiper', {
        modules: [Pagination, Autoplay, Navigation],
        pagination: {
          el: '.js-project-swiper .swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.js-carousel__next',
          prevEl: '.js-carousel__prev',
        },
        spaceBetween: 40,
        slidesPerView: 1,
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        },
        autoplay: {
          delay: 6000,
        },
        loop: true,
      });
    }

    const scrapbookSwiper = document.querySelector('.js-scrapbook-swiper');
    if (scrapbookSwiper) {
      new Swiper('.js-scrapbook-swiper', {
        modules: [Navigation],
        slidesPerView: 1.5,
        spaceBetween: 5,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          init: function () {
            let totalSlides = this.slides.length;
            document.querySelector('.js-carousel__count').textContent = totalSlides;
            document.querySelector('.js-carousel__current').textContent = this.realIndex + 1;
          },
          slideChange: function () {
            document.querySelector('.js-carousel__current').textContent = this.realIndex + 1;
          }
        },
        navigation: {
          nextEl: '.js-carousel__next',
          prevEl: '.js-carousel__prev',
        },
        breakpoints: {
          640: {
            slidesPerView: 1.5,
            spaceBetween: 2,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 2,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 2,
          },
        }
      });
    }

    const testimonialSwiper = document.querySelector('.js-testimonial-swiper');
    if (testimonialSwiper) {
      new Swiper('.js-testimonial-swiper', {
        modules: [Pagination, Autoplay, Navigation],
        pagination: {
          el: '.js-testimonial-swiper .swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        spaceBetween: 40,
        slidesPerView: 1,
        autoplay: {
          delay: 6000,
        },
        loop: true,
      });
    }
    isScriptLoaded = true;
  }

  const elements = [...document.querySelectorAll('.js-text-image-block-swiper, .js-project-swiper, .js-scrapbook-swiper, .js-testimonial-swiper')];
  elements.forEach((element) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(element);
  });
}
