// This module is used to track form submissions by sending information
// to the dataLayer which is picked up by Google Tag Manager, which
// proceeds to forward the event onwards to GA4.
export default function init() {
  const forms = [...document.querySelectorAll('.fui-form')];

  forms.forEach(/** @param {HTMLFormElement} form */ (form) => {
    form.addEventListener('onAfterFormieSubmit', (e) => {
      e.preventDefault();

      const { formHandle } = JSON.parse(form.dataset.fuiForm);

      // Set tracking object
      const tracking = {
        event: 'form_submission',
        form_handle: formHandle,
      };

      // Get the services that were selected
      const servicesCheckboxesSelector = '[name="fields[whatCanWeHelpWith][]"]';
      const servicesCheckboxes = form
        .querySelectorAll(servicesCheckboxesSelector);
      if (servicesCheckboxes.length) {
        const services = [];
        servicesCheckboxes.forEach((servicesCheckbox) => {
          if (servicesCheckbox.checked) {
            services.push(servicesCheckbox.value);
          }
        });
        // Append services to tracking object
        tracking.services = [...servicesCheckboxes]
          .filter(c => c.checked)
          .map(c => c.value);
      }

      // Check if free marketing insights was selected
      const freeMarketingCheckbox = form.querySelector('[type="checkbox"][name="fields[idLikeToReceiveFreeMarketingInsightsInMyInbox]"]');
      if (freeMarketingCheckbox) {
        // Append freeMarketing to tracking object
        tracking.free_marketing = freeMarketingCheckbox.checked ? true : null;
      }

      // Get the additional free marketing insights that were selected
      const additionalFreeMarketingCheckboxes = form.querySelectorAll('[name="fields[idAlsoLikeToReceiveFreeInsightsOnTheFollowingOpt][]"]');
      if (additionalFreeMarketingCheckboxes.length) {
        tracking.additional_free_marketing = [...additionalFreeMarketingCheckboxes]
          .filter(c => c.checked)
          .map(c => c.value);
      }

      window.dataLayer = window.dataLayer || [];

      dataLayer.push(tracking);
    });
  });
}
