/*
 Checks if cookiebot is defined and if statistics cookies are accepted. If so, adds the dnt=1 parameter to the vimeo iframe src
*/

export default function init() {
  window.addEventListener('CookiebotOnConsentReady', function () {
    const dnt = (typeof Cookiebot !== 'undefined' && Cookiebot.consent?.statistics ? 0 : 1)
    const videoWrappers = document.getElementsByClassName('b-video__embed-wrapper');
    
    for (const wrapper of videoWrappers) {
      const iframe = wrapper.querySelector('iframe');
      
      if (iframe) {
        let src = iframe.getAttribute('src');

        if (src) {
          const url = new URL(src);
          url.searchParams.set('dnt', dnt);
          src = url.toString();
          iframe.setAttribute('src', src);
        }
      }
    }
  });
}
