export default function init() {
  let isFormieScriptLoaded = false;
  let formieInstance = null;
  const initialisedFormies = [];
  const loadFormieScript = async (form) => {
    if (isFormieScriptLoaded) return;

    // load Formie CSS
    const {formieCssUrl} = form.dataset;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = formieCssUrl;
    document.head.appendChild(link);

    // load Formie JS
    const { Formie } = await import('../../../../craft/vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib');
    formieInstance = new Formie();

    isFormieScriptLoaded = true;
  };

  const forms = [...document.querySelectorAll('.fui-form')];
  forms.forEach((form) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadFormieScript(form);

        if (!initialisedFormies.includes(form)) {
          formieInstance.initForm(form);
          initialisedFormies.push(form);
        }
      });
    });
    observer.observe(form);
  });
}
