import '@styles/blocks/b-chevron-carousel.scss';

export default function init() {
  let isScriptLoaded = false;
  const loadScript = async () => {
    if (isScriptLoaded) return;
    
    import('swiper/swiper.min.css');
    Promise.all([
      import('swiper'),
      import('animejs/lib/anime.es.js'),
    ]).then(([swiperModule, animeModule]) => {
      const Swiper = swiperModule.default;
      const anime = animeModule.default;
      document.querySelectorAll('.js-chevron-carousel').forEach((el) => {
        const carousel = el.querySelector('.js-chevron-carousel__swiper');
        const swiper = new Swiper(carousel, {
          allowTouchMove: false,
          loop: true,
          slidesPerGroup: 1,
        });
    
        const chevron = el.querySelector('.js-chevron-carousel__chevron');
        const tl = anime.timeline({
          direction: 'alternate',
          delay: 3000,
          loop: true,
          easing: 'easeOutQuint',
        });
        tl
          // Animate the triangle clip path which clips the image with the blue background behind it
          .add({
            targets: el,
            clipPath: [
              'polygon(0 0, 100% 0, 100% 100%, 0 100%, calc(var(--chevron-depth) + 0) 50%)',
              'polygon(100% 0, 200% 0, 200% 100%, 100% 100%, calc(var(--chevron-depth) + 100%) 50%)',
            ],
            update(anim) {
              if (anim.progress === 100) {
                swiper.slideNext(0);
              }
            },
          })
          // Also animate the coloured arrow overlay
          .add({
            targets: chevron,
            translateX: [0, '100%'],
          }, 0);
      });
    });

    isScriptLoaded = true;
  };

  const elements = [...document.querySelectorAll('.js-chevron-carousel')];
  elements.forEach((element) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(element);
  });
}
