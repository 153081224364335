function toggleCardContent(e) {
  const { currentTarget } = e;

  const isCardOpen = currentTarget.classList.contains('is-open');
  if (isCardOpen) {
    return true;
  }

  // add class is-open
  currentTarget.classList.add('is-open');
}

export default function init() {
  document.querySelectorAll('.js-project-card').forEach((cardEl) => {
    cardEl.addEventListener('click', toggleCardContent);
  });
}
