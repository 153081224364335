export default function init() {
  let isScriptLoaded = false;
  const loadScript = async () => {
    if (isScriptLoaded) return;

    Promise.all([
      import('glightbox'),
      import('glightbox/dist/css/glightbox.css'),
    ])
      .then(([module]) => {
        const GLightbox = module.default;
        const dnt = (typeof Cookiebot !== 'undefined' && Cookiebot.consent?.statistics ? 0 : 1)

        const glightbox = GLightbox({
          selector: '.js-lightbox',
          plyr: {
            config: {
              vimeo: {
                title: false,
                dnt,
              },
            },
          },
        });
        glightbox.open();
        isScriptLoaded = true;
      })
      .catch(err => console.error(`Could not load module: ${err}`));
  };

  const elements = [...document.querySelectorAll('.js-lightbox')];
  elements.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      loadScript();
    });
  });
}
