/**
 * Sets the active state for a button. Does not perform a filter or
 * enforce mutual exclusivity.
 *
 * @param {HTMLElement} el
 * @param {boolean} isActive
 */
function setIsActive(el, isActive) {
  if (isActive) {
    el.classList.add('b-project-filter__category--active');
    el.setAttribute('aria-pressed', 'true');
  } else {
    el.classList.remove('b-project-filter__category--active');
    el.setAttribute('aria-pressed', 'false');
  }

  // "All" cannot be manually un-ticked
  if (el.classList.contains('js-project-filter__all')) {
    el.disabled = isActive;
  }
}

/**
 * @param {HTMLElement} project
 * @param {string} categoryGroup Category group handle
 * @param {number} categoryId
 */
function matchesCategory(project, categoryGroup, categoryId) {
  // All categories
  if (Number(categoryId) === 0) {
    return true;
  }

  /**
   * Map of category group handles to a comma-separated
   * list of category IDs for this project
   */
  const categoryIdMap = JSON.parse(project.getAttribute('data-category-ids') || '{}');

  // Get the category IDs for this category group from the map
  const categoryIds = categoryIdMap[categoryGroup] || '';

  // Check that this project's categories include the provided ID
  return categoryIds.split(',').includes(String(categoryId));
}

/**
 * Map of category group handles to a selected category ID, or 0 for "all"
 */
const selectedCategoryIds = {};

/**
 * Performs the filter by looping through all projects and checking them for the
 * selected category for each group
 */
function filter() {
  // Find all projects
  const projects = document.querySelectorAll('.b-project-listing');

  // Find the matching results
  projects.forEach((project) => {
    const matches = Object.entries(selectedCategoryIds).every(
      ([categoryGroup, categoryId]) => matchesCategory(project, categoryGroup, categoryId)
    );

    if (matches) {
      project.style.display = '';
    } else {
      project.style.display = 'none';
    }
  });
}

/**
 * Enables the given category, disables other categories for this group, and
 * applies the filter
 *
 * @param {HTMLElement} parent The element which contains all of the category
 *   buttons for a certain category group
 * @param {HTMLElement} el
 */
function filterCategory(parent, el) {
  // De-activate other categories
  const activeCategories = parent.querySelectorAll('.b-project-filter__category--active');
  activeCategories.forEach((category) => setIsActive(category, false));

  // Set this category as active
  setIsActive(el, true);

  /**
   * Craft handle for this category group
   */
  const categoryGroup = parent.getAttribute('data-category-group');

  // Mark the selected category group
  selectedCategoryIds[categoryGroup] = el.getAttribute('data-category-id');

  // Apply filter
  filter();
}

/**
 * Filter for a single category group
 *
 * @param {HTMLElement} parent
 */
function initProjectFilter(parent) {
  // Toggle a category
  parent.addEventListener('click', (e) => {
    const el = e.target.closest('.js-project-filter__category');
    if (!el) return;

    // Disable the category
    if (el.classList.contains('b-project-filter__category--active')) {
      const all = parent.querySelector('.js-project-filter__all');
      if (all && all !== el) {
        filterCategory(parent, all);
      }
    } else { // Enable the category
      filterCategory(parent, el);
    }
  });
}

export default function init() {
  document.querySelectorAll('.js-project-filter').forEach((el) => {
    initProjectFilter(el);
  });
}
