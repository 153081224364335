// Assuming you have already included js-cookie via a script tag or an ES module
import Cookies from 'js-cookie';

const toastDelay = 5000; // ms delay before showing the toast
const shownExpires = 365; // days before showing the same toast again

function showToast(toast) {
  const cookieBot = document.querySelector('#CybotCookiebotDialog');
  const cookieBotVisible = cookieBot && window.getComputedStyle(cookieBot).display !== 'none';

  if (cookieBotVisible) {
    setTimeout(() => showToast(toast), toastDelay);
  } else {
    toast.classList.toggle('active');
  }
}

function hideToast(toast) {
  toast.classList.remove('active');
  Cookies.set(toast.getAttribute('data-id'), 1, { expires: shownExpires });
}

function toastPopped(toast) {
  return Cookies.get(toast.getAttribute('data-id'));
}

export default function init() {
  const toast = document.querySelector('.b-toast');
  if (toast && !toastPopped(toast)) {
    toast.classList.remove('is-hidden');
    setTimeout(() => showToast(toast), toastDelay);

    const closeBtn = document.querySelector('.b-toast__close');
    if (closeBtn) {
      closeBtn.addEventListener('click', (e) => {
        e.stopPropagation();
        hideToast(toast);
        return false;
      });
    }

    toast.addEventListener('click', (e) => {
      e.preventDefault();
      hideToast(toast);
      window.location.href = toast.getAttribute('href');
    });
  }
}
