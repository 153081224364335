
/**
 * Formie's recommended solution to CSRF Token regeneration. See
 * {@link https://verbb.io/craft-plugins/formie/docs/template-guides/cached-forms#csrf-token}
 */
export default function init() {
  const forms = [...document.querySelectorAll('.fui-form')];

  forms.forEach(/** @param {HTMLFormElement} form */ (form) => {
    /** @type {Object} formConfig */
    const formConfig = JSON.parse(form.dataset.fuiForm);
    /** @type {string} formHandle */
    const { formHandle } = formConfig;
    fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
      .then(result => result.json())
      .then((result) => {
        // Update CSRF token
        const csrfInput = form.elements[result.csrf.param];

        if (csrfInput) {
          csrfInput.value = result.csrf.token;
        }

        // Update Captchas
        if (result.captchas) {
          Object.values(result.captchas).forEach((captcha) => {
            const input = form.elements[captcha.sessionKey];

            if (input) {
              input.value = captcha.value;
            }
          });
        }

        // Update the form's hash (if using Formie's themed JS)
        if (form.form && form.form.formTheme) {
          form.form.formTheme.updateFormHash();
        }
      });
  });
}
